@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
.accordion-wrapper {
	margin: 40px 0 20px 0;
	.accordion-header {
		font-size: 32px;
		line-height: 1.2;
		margin-top: 30px;
		margin-bottom: 10px;
		color: #62b4c5;
	}
}
